@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #faf8f3;           /* Very light vanilla */
    --color-bg-variant: #e7e3db;   /* Pale background variant */
    --color-primary-variant: #b0b0b2; /* Medium-light grey */
    --color-primary: #949496;      /* Slightly darker grey */
    --color-light: #404041;        /* Darker grey for contrast */
    --color-white: #2a2b2c;        /* Almost black text */;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

[data-theme='forest'] {
    --color-bg: #014421;
    --color-bg-variant: #436b45;
    --color-primary-variant: #6b936b;
    --color-primary: #9cbc94;
    --color-light: #d2e4d6;
    --color-white: #fff;
}

[data-theme='sunset'] {
    --color-bg: #003f5c;
    --color-bg-variant: #58508d;
    --color-primary-variant: #bc5090;
    --color-primary: #ff6361;
    --color-light: #ffa600;
    --color-white: #fff;
}

[data-theme='autumn'] {
    --color-bg: #763626;
    --color-bg-variant: #a35b37;
    --color-primary-variant: #cf9e71;
    --color-primary: #d9c099;
    --color-light: #f6e6d1;
    --color-white: #fff;
}

[data-theme='candy'] {
    --color-bg: #6a4c93;
    --color-bg-variant: #1982c4;
    --color-primary-variant: #8ac926;
    --color-primary: #ffca3a;
    --color-light: #ff595e;
    --color-white: #fff;
}

[data-theme='yoda'] {
    --color-bg: #6c584c;
    --color-bg-variant: #a98467;
    --color-primary-variant: #adc178;
    --color-primary: #dde5b4;
    --color-light: #f0ead2;
    --color-white: #fff;
}

[data-theme='midnight'] {
    --color-bg: #1e1f26;
    --color-bg-variant: #28313b;
    --color-primary-variant: #4b5364;
    --color-primary: #596275;
    --color-light: #ced6e0;
    --color-white: #fff;
}

[data-theme='black'] {
    --color-bg: #000000;
    --color-bg-variant: #3d010e;
    --color-primary-variant: #79021c;
    --color-primary: #b6042a;
    --color-light: #f50538;
    --color-white: #fff;
}

[data-theme='aurora'] {
    --color-bg: #021d49;
    --color-bg-variant: #062f65;
    --color-primary-variant: #385ca2;
    --color-primary: #5b81c6;
    --color-light: #aed1ff;
    --color-white: #fff;
}

[data-theme='purple'] {
    --color-bg: #660e60;
    --color-bg-variant: #893f71;
    --color-primary-variant: #ac6f82;
    --color-primary: #cfa093;
    --color-light: #f3d0a4;
    --color-white: #fff;
}

[data-theme='vanilla-classic'] {
    --color-bg: #f9f7f3;
    --color-bg-variant: #eae3d9;
    --color-primary-variant: #5d5f71;
    --color-primary: #3c3f51;
    --color-light: #2b2d42;
    --color-white: #212121;
}

[data-theme='cotton-noir'] {
    --color-bg: #f5f5f5;
    --color-bg-variant: #d7d7d7;
    --color-primary-variant: #505a67;
    --color-primary: #3b4855;
    --color-light: #2d3744;
    --color-white: #212121;
}

[data-theme='cotton-slate'] {
    --color-bg: #f4f4f4;
    --color-bg-variant: #dcdcdc;
    --color-primary-variant: #555e6c;
    --color-primary: #3d4555;
    --color-light: #2b2f3b;
    --color-white: #1a1a1a;
}

[data-theme='vanilla-mist'] {
    --color-bg: #faf8f3;           /* Very light vanilla */
    --color-bg-variant: #e7e3db;   /* Pale background variant */
    --color-primary-variant: #b0b0b2; /* Medium-light grey */
    --color-primary: #949496;      /* Slightly darker grey */
    --color-light: #404041;        /* Darker grey for contrast */
    --color-white: #2a2b2c;        /* Almost black text */
}








html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url(../src/assets/bg_texture_black.jpg) */
}

/* #################### GENERAL STYLES #################### */
.container {
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    height: auto;
}

section > h2,
section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-decoration: underline;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-flex;  /* Changed from inline-block to inline-flex */
    justify-content: center;  /* Center content horizontally */
    align-items: center;  /* Center content vertically */
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    text-align: center;  /* Ensure text is centered if it wraps */
}

/* Rest of the CSS remains the same */

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
@media screen and (max-width: 1024px) {

    section {
        margin-top: 6rem;
    }
}

/* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
@media screen and (max-width: 600px) {

    section > h2 {
        margin-bottom: 2rem;
    }
}
