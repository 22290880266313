#about {
    padding-top: 60px;
  }
  
  .section-title {
    text-align: center;
    font-size: 2.5rem;
    color: var(--color-primary);
    text-transform: uppercase;
    letter-spacing: 3px;
    animation: fadeInDown 1s ease;
    margin-bottom: 2rem;
  }
  
  h3 {
    text-decoration: underline;
  }

  .about_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-bg-variant);
    padding: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    animation: fadeInUp 1s ease;
  }
  
  .about_box:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
  }
  
  .about_us {
    flex: 1;
    margin-right: 2rem;
    animation: fadeInLeft 1s ease;
  }
  
  .about_us-image {
    overflow: hidden;
    border: 5px solid var(--color-primary-variant); 
    box-shadow: 0px 8px 15px var(--color-bg-variant);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .about_us-image:hover {
    transform: scale(1.02);
    box-shadow: 0px 12px 20px var(--color-bg-variant);
  }
  
  .about_us-image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  
  .about_content {
    flex: 2;
    color: var(--color-light);
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .about-heading {
    font-size: 1.8rem;
    color: var(--color-primary);
    margin-bottom: 1rem;
    animation: fadeInRight 1s ease;
  }
  
  .about-text {
    font-size: 1.2rem;
    color: var(--color-white);
    margin-bottom: 1.5rem;
    animation: fadeIn 1s ease;
  }
  
  .about_button {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  /* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
  @media screen and (max-width: 1024px) {
    .about_box {
      flex-direction: column;
      text-align: center;
      padding: 1.5rem;
    }
  
    .about_us {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }
  
  /* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
  @media screen and (max-width: 600px) {
    .about_box {
      width: 100%;
      padding: 1rem;
    }
  
    .about_content {
      font-size: 1rem;
    }
  }
  
  /* #################### KEYFRAMES #################### */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    0% { opacity: 0; transform: translateY(30px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes fadeInLeft {
    0% { opacity: 0; transform: translateX(-30px); }
    100% { opacity: 1; transform: translateX(0); }
  }
  
  @keyframes fadeInRight {
    0% { opacity: 0; transform: translateX(30px); }
    100% { opacity: 1; transform: translateX(0); }
  }
  
  @keyframes fadeInDown {
    0% { opacity: 0; transform: translateY(-30px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  