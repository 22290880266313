/* Existing nav styles */

nav {
    background: var(--color-primary-variant);
    width: 100%;
    padding: 0.5rem 4rem;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
}

nav a.logo {
    margin-right: auto;
    padding-right: 2rem;
}

nav a.logo img {
    height: 60px;
    width: auto;
    cursor: pointer;
}

nav .nav-links {
    display: flex;
    gap: 1rem;
    transition: max-height 0.3s ease;
}

nav .nav-links a {
    background: transparent;
    padding: 0.5rem 1rem;
    border-radius: 0; /* Removed rounded corners */
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: var(--color-light);
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
}

nav .nav-links a:hover {
    background: var(--color-primary-variant);
    color: var(--color-bg);
}

nav .nav-links a.active {
    background: var(--color-primary);
    color: var(--color-bg);
    border-radius: 0; /* Sharp corners for active link */
}

nav .menu-toggle {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: var(--color-light);
}

/* #################### MEDIA QUERIES #################### */

@media screen and (max-width: 700px) {
    nav .nav-links {
        position: absolute;
        top: 100%;
        left: 0;
        background: var(--color-primary-variant);
        width: 100%;
        flex-direction: column;
        align-items: center;
        max-height: 0;
        overflow: hidden;
    }

    nav .nav-links.open {
        max-height: 300px;
    }

    nav .nav-links a {
        width: 100%;
        justify-content: center;
        padding: 1rem;
    }

    nav .menu-toggle {
        display: block;
        margin-left: auto;
    }
}
