.theme-dropdown-container {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    margin-left: 1rem;
  }
  
  .theme-label {
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
    color: var(--color-primary);
    font-weight: 600;
  }
  
  .theme-dropdown {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    background-color: var(--color-primary);
    color: var(--color-bg);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 0; /* Remove border-radius for sharper corners */
  }
  
  .theme-dropdown:hover {
    background-color: var(--color-white);
    color: var(--color-bg);
  }
  
  .theme-dropdown:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-white);
  }
  
  .theme-dropdown-container::after {
    content: '\25BC';
    position: absolute;
    bottom: 10px;
    right: 10px;
    pointer-events: none;
    color: var(--color-bg);
  }
  
  @media screen and (max-width: 1024px) {
    .theme-label {
      font-size: 0.8rem;
    }
  
    .theme-dropdown {
      font-size: 0.9rem;
      padding: 0.4rem 1.8rem 0.4rem 0.8rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .theme-label {
      font-size: 0.75rem;
    }
  
    .theme-dropdown {
      font-size: 0.8rem;
      padding: 0.3rem 1.6rem 0.3rem 0.7rem;
    }
  }