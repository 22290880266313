#contact {
  padding-top: 60px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  color: var(--color-primary);
  text-transform: uppercase;
  letter-spacing: 3px;
  animation: fadeInDown 1s ease;
  margin-bottom: 2rem;
}

h3 {
  text-decoration: none;
}

.contact_box {
  display: flex;
  align-items: center; /* Center image vertically */
  background-color: var(--color-bg-variant);
  padding: 4rem;
  border: 1px solid transparent;
  transition: var(--transition);
  animation: fadeInUp 1s ease;
}

.contact_box:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact_us {
  flex: 2;
  display: flex;
  justify-content: center; /* Center the image horizontally */
  margin-right: 2rem;
  animation: fadeInLeft 1s ease;
}

.contact_us-image {
  overflow: hidden;
  border: 5px solid var(--color-primary-variant);
  box-shadow: 0px 8px 15px var(--color-bg-variant);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.contact_us-image:hover {
  transform: scale(1.02);
  box-shadow: 0px 12px 20px var(--color-bg-variant);
}

.contact_us-image img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.contact_content {
  flex: 2;
  color: var(--color-light);
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center; 
}

.contact_option {
  margin-bottom: 1.5rem;
  animation: fadeIn 1s ease;
}

.contact_option-icon {
  font-size: 1.5rem;
  color: var(--color-primary);
  margin-bottom: 0.5rem;
}

.contact-text {
  font-size: 1.2rem;
  color: var(--color-white);
  margin-bottom: 1.5rem;
  animation: fadeIn 1s ease;
}

.contact-text a {
  color: var(--color-primary);
  text-decoration: none;
  transition: var(--transition);
}

.contact-text a:hover {
  color: var(--color-white);
}

.social_media {
  margin-top: 2rem;
}

.social_buttons {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 0.5rem;
  justify-content: center; 
}

.social_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: var(--color-bg);
  font-size: 1.2rem;
  transition: var(--transition);
}

.social_button:hover {
  background-color: var(--color-white);
  color: var(--color-bg);
  transform: translateY(-3px);
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .contact_box {
      flex-direction: column;
      text-align: center;
      padding: 1.5rem;
  }

  .contact_us {
      margin-right: 0;
      margin-bottom: 1.5rem;
      justify-content: center; /* Keeps the image centered */
  }

  .contact_content {
      text-align: center; /* Center the text on smaller screens */
      margin-left: 0; /* Remove gap on smaller screens */
  }
}

@media screen and (max-width: 600px) {
  .contact_box {
      width: 100%;
      padding: 1rem;
  }

  .contact_content {
      font-size: 1rem;
  }
}

/* Keyframes */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInLeft {
  0% { opacity: 0; transform: translateX(-30px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInRight {
  0% { opacity: 0; transform: translateX(30px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInDown {
  0% { opacity: 0; transform: translateY(-30px); }
  100% { opacity: 1; transform: translateY(0); }
}
