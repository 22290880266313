/* Ensure you have this at the beginning of your CSS to import fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

/* General Header Styling */
header {
    text-align: center;
    padding-top: 7rem;
    overflow: hidden;
    position: relative;
}

.photo-frame {
    display: inline-block;
    padding: 10px;
    background-color: var(--color-primary-variant);
    border: 5px solid var(--color-primary);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    position: relative; /* For positioning the typing text */
    width: 100%; /* Ensure full-width responsiveness */
    max-width: 100%; /* Prevent overflow */
}

.photo-frame img {
    display: block;
    width: 100%;
    height: auto; /* Ensures image scales properly */
    max-height: 600px; /* Set a maximum height */
}


/* Container Styling */
.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

h2 {
    margin-bottom: 1rem;
}

h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

h4 {
    margin-top: 0;
    margin-bottom: 1rem;
}

/* Text Box Styling */
.text-box {
    background: var(--color-bg-variant);
    padding: 2.4rem 2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 100%;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    max-width: 60%;
    position: relative;
    z-index: 1;
    margin-bottom: 1rem;
}

.text-box:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.text-box h3 {
    text-align: center;
    color: var(--color-primary);
}

/* CTA Styling */
.cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-direction: unset;
}

.scroll_down {
    position: absolute;
    right: 0.02rem;
    bottom: 10rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* Typing Text Styling */
.typing-text {
    position: absolute;
    bottom: 10px;
    left: 5%; /* Responsive positioning */
    font-size: 6vw; /* Responsive font size */
    color: var(--color-primary);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    width: auto;
    padding: 0.5vw; /* Responsive padding */
    text-shadow: 
        1px 1px 0 var(--color-bg-variant),
        2px 2px 0 var(--color-bg);
}

/* Keyframes for Typing Animation */
@keyframes typing {
    0% {
        width: 0px;
    }
    50% {
        width: 30vw; /* Responsive width */
    }
    100% {
        width: 60vw; /* Responsive width */
    }
}

@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}
