footer {
    background: var(--color-primary);
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    margin-bottom: 7rem;
    width: 100%;
    box-sizing: border-box;
}

.main-footer {
    background: var(--color-primary);
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 4rem;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer a {
    color: var(--color-bg);
}

.footer_logo {
    font-size: 1.5rem;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 0.5rem;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 1rem;
    color: var(--color-bg);
}

.permalinks_two {
    display: inline-block;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 1rem;
    color: var(--color-bg);
    padding: 0.2rem;
}

.footer_socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
    font-size: 24px;
}

.footer_socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer_socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

.footer-images img {
    width: 150px; /* Increase the size of the images */
    height: auto;
}

.footer_copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

/* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer_socials {
        margin-bottom: 2.6rem;
    }

    .footer-images {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .main-footer {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}
