/* Container for the services section */
.services_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 100%;
  }

  .service_card {
    width: 100%;
    padding: 2rem;
    color: var(--color-light);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .service_card h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-decoration: underline;
  }
  
  .service_card p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Alternating colors for the service cards */

  .service_card.even {
    background-color: var(--color-bg-variant);
    border: 1px solid transparent;
    transition: var(--transition);
  }
  
  .service_card:hover {
    transform: translateY(-10px);
    background: transparent;
    border-color: var(--color-primary-variant);
  }
  
  /* Section padding to separate it from the previous section */
  section#services {
    padding: 4rem 0; /* Increased top padding for better spacing */
  }
  
  /* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
  @media screen and (max-width: 1024px) {
    .services_container {
      padding: 3rem 1.5rem; /* Reduced padding for medium devices */
    }
  
    .service_card {
      padding: 2rem;
    }
  }
  
  /* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
  @media screen and (max-width: 600px) {
    .services_container {
      padding: 2rem 1rem; /* Further reduced padding for small devices */
      gap: 1.5rem;
    }
  
    .service_card {
      padding: 1.5rem;
    }
  
    .service_card h3 {
      font-size: 1.3rem;
    }
  
    .service_card p {
      font-size: 0.9rem;
    }
  }
  